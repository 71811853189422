import "./App.css";

import { useEffect, useRef } from "react";

import Epk from "./epk";
import OTLPbuy from "./otlp"
import { fadeIn } from "./hooks/useAnimation";
import { inject } from '@vercel/analytics';
 
inject();

function App() {
  const mainRef = useRef();

  useEffect(() => {
    fadeIn(mainRef.current, 0.005);
  }, []);

  return (
    <main className="font-mavenRegular fade-in main text-center items-center justify-center" ref={mainRef}>
      {/* <div className="flex flex-col justify-center text-center gap-20 pt-16">
        <div className="text-3xl sticky  z-50 text-white">
          badkuzu
        </div>
        <div className="text-3xl absolute text-center text-white">
          <div className="opacity-60 ">badkuzu</div>
        </div>
      </div> */}
      <div class="sticky top-0 p-10 font-mavenSemiBold text-white text-3xl">
        badkuzu
      </div>
      <div class="absolute top-0 w-full opacity-50 p-10 font-mavenSemiBold text-white text-3xl">
        badkuzu
      </div>
      <OTLPbuy />
      <br/><br/>
      <Epk />
      <div className="background" />
      <div style={{ height: "100px", position: "relative" }}></div>
    </main>
  );
}

export default App;
