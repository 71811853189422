export function fadeOut(element, rate = 0.01) {
  let intervalID = setInterval(() => {
    if (!element.style.opacity) {
      element.style.opacity = 1;
    }

    if (Number(element.style.opacity) > 0) {
      element.style.opacity = Number(element.style.opacity) - rate;
    } else {
      clearInterval(intervalID);
    }
  }, 1);
}

export function fadeIn(element, rate = 0.01) {
  let intervalID = setInterval(() => {
    if (Number(element.style.opacity) < 1) {
      element.style.opacity = Number(element.style.opacity) + rate;
    } else {
      clearInterval(intervalID);
    }
  }, 1);
}
