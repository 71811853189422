import { useCallback, useState, createContext } from "react";

const LoginContext = createContext();

export function LoginContextProvider({ children }) {
  const [password, setPassword] = useState("");
  const [soundcloudToken, setSoundcloudToken] = useState("");
  const [access, setAccess] = useState(false);
  const [error, setError] = useState(false);

  const submitForm = useCallback(async () => {
    const response = await fetch(
      "https://europe-west3-kuzupromo.cloudfunctions.net/access-check",
      {
        method: "POST",
        body: JSON.stringify({ password }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const token = await response.text();

    if (response.status === 200) {
      setSoundcloudToken(token);
      setAccess(true);
    } else {
      setError(true);
    }
  }, [password]);

  return (
    <LoginContext.Provider
      value={{
        submitForm,
        setPassword,
        soundcloudToken,
        access,
        error,
        setError,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export default LoginContext;
