/* eslint-disable no-multi-str */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          pp: "post-patriarchal electronic dance music",
          musicwarn:
            "Partially unreleased recordings - for promo purposes only. Please do not distribute.",
          p1: "Badkuzu's number one goal while performing is to make the listener \
        dance and liberate their emotions. The instruments he plays are drums, \
        synthesizers, voice and the computer, but having the luck of belonging \
        to an amazing community of artists and the rare privilege of freedom \
        of movement around most of the planet, he is able to incorporate a \
        much wider range of sounds than those he can produce himself. These are usually \
        parts recorded by the musicians he collaborates with, scraped from the \
        corners of the internet or captured while traveling. ",
          p2: "Some of his strongest musical influences include Electronic Dance \
        Music, traditional music of North Africa, India and Middle East, \
        Eastern / Central European classical music and jazz. \
        Badkuzu's favourite topics are technology, futurism, the cosmos and anti-patriarchy.",
          p3: "Born in confused post cold-war Poland, \
        small Kuba developed a passion for hitting things, breaking them \
        apart, twisting knobs, smashing on buttons and dissociating from reality \
        to the sound of cassette tapes and compact disks. As a teenager he \
        spent countless hours practicing the drumset and producing music with \
        his computer. He started touring and recording as a drummer at the age \
        of 15, and carried on with drumming as his main point of focus into \
        his twenties. He moved to Berlin in 2011, where he became Bachelor of \
        Jazz Drumset at the Berlin University of Arts and worked as a \
        professional session drummer for numerous artists representing a wide \
        range of genres and backgrounds.",
          p4: "Between 2011 and 2020 he has contributed drum parts to numerous \
        recordings, performed on four continents and worked extensively as \
        a session musician. Being a \
        nerd at heart, in 2020 he decided to take a break from music and \
        focus on software engineering. This adventure has made him grow in \
        many unexpected ways, one of which was coming back to working with the \
        computer as a musical instrument, the exploration of sound synthesis, \
        and the genesis of the project Badkuzu.",
          p5: "The unofficial Badkuzu premiere took place at Bucht der \
        Träumer Festival 2022, and the official one took place in November \
        2022 during the artists 30's birthday party, where he was accompanied \
        by an array of amazing DJ's and live performers from the Berlin scene \
        such as Entelechion, Ori Moto, Pegaxadi, BFAN, Djoon, Tarxun and Joy \
        Tyson.",
          p6: 'For 2023 the artist is looking forward to make appearances in clubs and on \
        festivals with the live set containing music from two of his upcoming albums - \
        "music for dancing" and "Heal".',
          tech_req: "Technical requirements:",
          r1: "A flat surface fitting 3 laptop sized devices",
          r2: "Microphone stand",
          r3: "Two audio channels to plug into (balanced 1/4 TRS out from sound card)",
          r4: "PA",
          r5: "Water",
          upcoming: "Upcoming shows",
          past: "Past shows",
        },
      },
      de: {
        translation: {
          pp: "Postpatriarchalische elektronische Tanzmusik",
          musicwarn:
            "Teilweise unveröffentlichte Aufnahmen - nur für Promozwecke. Bitte nicht verbreiten.",
          p1: "Badkuzus oberstes Ziel bei seinen Auftritten ist es, die Zuhörer \
           zum Tanzen zu bringen und ihre Gefühle zu befreien. Seine Instrumente sind \
           Schlagzeug, Synthesizer, Stimme und Computer, aber da er das Glück hat, \
            einer erstaunlichen Gemeinschaft von Künstlern anzugehören, und das \
            seltene Privileg hat, sich in fast allen Teilen \
            der Welt frei bewegen zu können, ist er in der Lage, ein viel breiteres \
            Spektrum an Klängen einzubeziehen als die, die er selbst produzieren kann. \
            Dabei handelt es sich in der Regel um Teile, die von den Musikern, \
            mit denen er zusammenarbeitet, aufgenommen wurden, die er aus den Ecken \
            des Internets zusammengekratzt oder auf Reisen aufgenommen hat.",
          p2: "Zu seinen stärksten musikalischen Einflüssen gehören elektronische Tanzmusik, \
             traditionelle Musik Nordafrikas, Indiens und des Nahen Ostens, ost- und mitteleuropäische \
            klassische Musik und Jazz. Badkuzu's Lieblingsthemen sind \
            Technologie, Futurismus, der Kosmos und das Anti-Patriarchat.",
          p3: "Geboren im verwirrten Polen der Nach-kaltkriegszeit, entwickelte \
           der kleine Kuba eine Leidenschaft dafür, auf Dinge einzuschlagen, \
           sie zu zerlegen, an Knöpfen zu drehen, Knöpfe zu drücken und sich zum Klang \
           von Kassetten und Compact Discs von der Realität zu lösen. Als Teenager \
           verbrachte er unzählige Stunden damit, am Schlagzeug zu üben und mit seinem \
            Computer Musik zu produzieren. Im Alter von 15 Jahren begann er als Schlagzeuger \
             zu touren und Aufnahmen zu machen, und bis in seine Zwanziger hinein war das \
              Schlagzeugspielen sein Hauptschwerpunkt. 2011 zog er nach Berlin, wo er den \
               Bachelor of Jazz Drumset an der Universität der Künste Berlin absolvierte und als \
                professioneller Session-Drummer für zahlreiche Künstler \
                unterschiedlichster Genres und Hintergründe arbeitete.",
          p4: "Zwischen 2011 und 2020 hat er Schlagzeugparts zu zahlreichen Aufnahmen \
                 beigesteuert, ist auf vier Kontinenten aufgetreten und hat intensiv als \
                   Session-Musiker gearbeitet. Da er im Herzen \
                   ein Nerd ist, hat er 2020 beschlossen, eine Pause von der Musik zu machen und sich \
                    auf Software Engineering zu konzentrieren. Dieses Abenteuer hat ihn auf viele unerwartete \
                     Arten wachsen lassen, eine davon war die Rückkehr zur Arbeit mit dem Computer als \
                     Musikinstrument, die Erforschung der Klangsynthese und die Entstehung des Projekts Badkuzu.",
          p5: "Die inoffizielle Badkuzu-Premiere fand auf dem Bucht der Träumer Festival 2022 statt, \
                      die offizielle im November 2022 auf der Geburtstagsparty des Künstlers zum 30. Geburtstag, \
                       bei der er von einer Reihe großartiger DJs und Live-Performer aus der Berliner Szene wie \
                        Entelechion, Ori Moto, Pegaxadi, BFAN, Djoon, Tarxun und Joy Tyson begleitet wurde.",
          p6: 'Für 2023 freut sich der Künstler auf Auftritte in Clubs und auf Festivals mit dem Live-Set, \
           das Musik aus zwei seiner kommenden Alben enthält – „Music for Dancing“ und „Heal“.',
          tech_req: "Technische Voraussetzungen:",
          r1: "Eine ebene Fläche, auf die 3 Geräte in Laptop-Größe passen",
          r2: "Mikrofonständer",
          r3: "Zwei Audiokanäle zum Einstecken (symmetrischer 1/4 TRS-Ausgang von der Soundkarte)",
          r4: "PA",
          r5: "Wasser",
          upcoming: "Kommende Shows",
          past: "Vergangenen shows",
        },
      },
    },
  });

export const languages = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};

export default i18n;
