import React, { useState, useEffect } from "react";
import './ProgressBar.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL

const PlainAudioPlayer = () => {
    const [number, setNumber] = useState(0);

    const incrementNumber = () => setNumber((prevNumber) => (prevNumber + 1) % 3);

    const audioUrl = ['media/sinsloop.mp3', 'media/owlloop.mp3', 'media/losungloop.mp3'];

    useEffect(() => {
        const audioObject = document.getElementById('audio');
        audioObject.src = audioUrl[0];
        audioObject.loop = true;
    }, [])

    const audioOnClick = () => {
        const audioObject = document.getElementById('audio');
        const img = document.getElementById('control');

        if (audioObject.paused) {
            audioObject.play();
            img.src = 'pause.webp'
            incrementNumber()
        } else {
            audioObject.pause();
            img.src = 'play.webp'
            audioObject.src = audioUrl[number]
        }
    }

    return (
        <div className="w-full">
            {/* <button onClick={playAudio}>Play Audio</button> */}
            <audio id="audio" className="justify-center inline">
                Your browser does not support the audio element.
            </audio>
            <img id="control" src="play.webp" className="justify-center inline w-24 cursor-pointer" onClick={audioOnClick}></img>
        </div>
    );
};

const ProductDisplay = () => (
    <section style={{ color: "white" }} className="text-center w-full p-8">
        <div className="product w-full">
            <div className="pb-8">
                <PlainAudioPlayer />
            </div>
            <div className="description pb-8">
                <div className="text-3xl">on the last patriarch EP</div>
                <h5 className="text-xl">7.00 €</h5>
            </div>
        </div>
        <form action={backendUrl + "/create-checkout-session"} method="POST">
            <button type="submit" className="p-6 cyberpunk-button">
                <div className="text-2xl font-bold">Buy directly from the artist</div>
                <div>~97% of your fee goes to badkuzu</div>
            </button>
            <br />
        </form>
        <br /><br />
        <div className="w-full">
            <div className="text-left justify-center md:w-2/5 lg:w-1/4 inline-block">
                <div>Included:</div>
                <br />
                <h2 className="pb-2"><b>Audio:</b></h2>
                <ul>
                    <li>1. Sins</li>
                    <li>2. The Boy and the Owl</li>
                    <li>3. Lösung</li>
                    <li>x. Sins (Instrumental)</li>
                </ul>
                <br />
                <p>Available in wav and mp3 formats.</p>
                <br /><br />
                <div>
                    <h2 className="pb-2"><b>Artwork:</b></h2>
                    <ul>
                        <li>- 3x single artwork</li>
                        <li>- EP artwork</li>
                    </ul>
                </div>
                <br />
                <p>Available in regular and extremely high resolution.</p>
                <br /><br />
                <div class="interactive-object">
                    <h2 className="pb-2"><b>Extra:</b></h2>
                    <ul>
                        <li>- aurora_figure.obj</li>
                    </ul>
                    <br />
                    <p>An interactive object file of the Aurora figure, <br />as seen in the live visual and on the cover. Works with Mac OS, or with any object viewer app on other systems.</p>
                </div>
            </div>
        </div>
    </section>
);

const SUCCESS = "success"
const CANCELED = "canceled"
const CHECKOUT_SESSION_ID = "checkout_session_id"
const HIGH = "high"
const LOW = "low"

// post purchase
const Message = ({ message }) => {
    const [highSpeedButtonText, setHighSpeedButtonText] = useState('hi res download*');
    const [lowSpeedButtonText, setLowSpeedButtonText] = useState('speedy download*');

    const query = new URLSearchParams(window.location.search);
    const checkout_session_id = query.get(CHECKOUT_SESSION_ID);

    const handleDownload = async (quality) => {
        // CHECK SESSION ID
        try {
            // get the temporary url for object
            const signedObjectUrl = await fetch(backendUrl + "/download_otlp?quality=" + quality + "&checkout_session_id=" + checkout_session_id).then(response => response.text())
                .then(data => data);

            // download the file
            window.open(signedObjectUrl);

            if (quality === HIGH) setHighSpeedButtonText('downloading...');
            if (quality === LOW) setLowSpeedButtonText('downloading...');
            setTimeout(() => {
                if (quality === HIGH) setHighSpeedButtonText('hi res download*');
                if (quality === LOW) setLowSpeedButtonText('speedy download*');
            }, 5000);
        } catch (error) {
            console.error('Error fetching file:', error);
            if (quality === HIGH) setHighSpeedButtonText('error, try again');
            if (quality === LOW) setLowSpeedButtonText('error, try again');
            setTimeout(() => {
                if (quality === HIGH) setHighSpeedButtonText('hi res download*');
                if (quality === LOW) setLowSpeedButtonText('speedy download*');
            }, 5000);
        }
    };

    if (message === SUCCESS) {
        return (
            <section className="text-center text-white py-12 px-8">
                Thank you for your purchase! ❤️ <br /><br />
                Get your files here:
                <br /><br />
                <div className="w-60 inline-block">
                    <div onClick={() => handleDownload(HIGH)} style={{ border: "2px solid" }} className="cyberpunk-button cursor-pointer w-full progress-bar relative">
                        <div className="p-2 z-10">{highSpeedButtonText}</div>
                    </div>
                </div>
                <br /><br />
                <div className="w-60 h-10 inline-block">
                    <div onClick={() => handleDownload(LOW)} style={{ border: "2px solid" }} className="cyberpunk-button cursor-pointer w-full progress-bar relative">
                        <div className="p-2 z-10">{lowSpeedButtonText}</div>
                    </div>
                </div>
                <br /><br /><br /><br />
                This page is active for you for 24 hours, <br /> you can come back to it later if you need better internet connection. <br /><br /> If that's the case, save the address from your browser now. <br /><br />
                If any issues would occur, or if you loose your files, <br /> please contact info@badkuzu.com <br /> and provide the email address you used during payment ❤️ <br /><br />
                *hi res download is ~450mb, speedy download is ~70mb <br/><br/>
            </section>
        )
    } else if (message === CANCELED) {
        return (
            <section className="text-center text-white w-full">
                <div className="justify-center w-1/2 md:w-2/5 lg:w-1/3 inline-block">
                    Payment was canceled. If you think there was an error please contact info@badkuzu.com and provide the email address you used during the transaction <br />
                    ❤️
                </div>
                <ProductDisplay />
            </section>
        )
    }
};


export default function App() {
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get(SUCCESS)) {
            setMessage(SUCCESS);
        }

        if (query.get(CANCELED)) {
            setMessage(CANCELED);
        }
    }, []);

    return (
        <>
            <div className="product w-full">
                <img
                    src="media/EP_COVER_small.jpg"
                    alt="on the last patriarch EP cover"
                    className="justify-center inline pb-8 w-3/4 md:w-1/2 xl:w-1/3"
                />
            </div>
            {message ? (
                <Message message={message} />
            ) : (
                <ProductDisplay />
            )};
        </>)
}